import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { EMAIL_PATTERN } from "src/app/constants/email.pattern";
import { Pages } from "src/app/enums/pages.enum";
import { CertificatesFirebase } from "src/app/firebase/certificates.firebase";
import { PortfolioFirebase } from "src/app/firebase/portfolio.firebase";
import { PropertiesFirebase } from "src/app/firebase/properties.firebase";
import { SubscriptionsFirebase } from "src/app/firebase/subscriptions.firebase";
import { Subscriptions } from "src/app/interface/subscriptions.interface";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public portfolio$: Observable<any>;
  public properties$: Observable<any>;
  public certificates$: Observable<any>;
  public form: FormGroup;
  public date: number;

  public layout: number | string = 'grey-bg-footer';
  public logo: number | string = 'assets/logo/ejfm-black.png'
  public shape: number | string = 'assets/logo/background-004.png';
  public shape2: number | string = 'assets/logo/background-003.png';
  public shapeclass: number | string = 'line-three';
  public shape2class: number | string = 'line-four';
  public currentPage: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private propertiesFirebase: PropertiesFirebase,
    private portfolioFirebase: PortfolioFirebase,
    private certificatesFirebase: CertificatesFirebase,
    private subscriptionsFirebase: SubscriptionsFirebase,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.certificates$ = this.certificatesFirebase.get$();
    this.properties$ = this.propertiesFirebase.get$();
    this.portfolio$ = this.portfolioFirebase.get$();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          this.currentPage = event.urlAfterRedirects;
      }
    });
  }

  ngOnInit() {
    this.form = this.formSetup();
    this.date = Date.now();
  }

  public gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  public sectionScroll(section: string) {
    let link: Pages = section as unknown as Pages;
    if (this.currentPage.substring(1) === Pages.Home || this.currentPage.substring(1) === 'home') {
        this.document.querySelector(section).scrollIntoView({ behavior: 'smooth' });
    } else {
        this.router.navigate([Pages.Home]);
    }
}

  public bitbatbow() {
    this.document.location.href = 'href="http://www.bitbatbow.com';
  }

  public onSubmit() {
    if (this.form.valid && !this.form.pristine) {
      const subscriber: Subscriptions = {
        email: this.form.get('email').value
      }

      if(subscriber.email) {
        this.subscriptionsFirebase.add(subscriber);
        this.form.reset();
      }
    }
  }



  private formSetup() {
    return this.fb.group({
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]]
    })
  }
}