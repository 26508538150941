<div class="cta-wrap bg-img-c" style="background-image: url(assets/logo/cta-background.png);">
    <div class="row justify-content-center">
        <div class="col-lg-8">
            <div class="cta-content text-center">
                <div class="section-title both-border mb-30">
                    <span class="title-tag">Get A Quote</span>
                    <h2 class="title">Would you like us to look after your Facilities needs?</h2>
                </div>
                <a routerLink="/contact" class="main-btn main-btn-3">Contact Us</a>
            </div>
        </div>
    </div>
</div>