import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
    public loading: boolean = true;

    constructor() { }

    ngOnInit() {
        setTimeout( () => {
            this.loading = !this.loading;
        }, 2000);
    }
}