import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EMAIL_PATTERN } from "src/app/constants/email.pattern";
import { ContactsFirebase } from "src/app/firebase/contacts.firebase";
import { Contact } from "src/app/interface/contacts.interface";

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
    public form: FormGroup;
    public contact: Contact;

    constructor(
        private fb: FormBuilder,
        private contactsFirebase: ContactsFirebase
    ) { }

    ngOnInit() {
        this.form = this.formSetup();
    }

    public onSubmit() {
        if (this.form.valid && !this.form.pristine) {
            this.contact = {
                name: this.form.get('name').value,
                telephone: this.form.get('telephone').value,
                email: this.form.get('email').value,
                message: this.form.get('message').value
            }

            if (this.contact.name && this.contact.email && this.contact.telephone) {
                this.contactsFirebase.add(this.contact);
                this.form.reset();
            }
        }
    }

    private formSetup() {
        return this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
            telephone: ['', Validators.required],
            message: ['', Validators.required]
        });
    }
}