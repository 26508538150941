import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Pages } from 'src/app/enums/pages.enum';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    @Input() public properties$: Observable<any>;
    public navigation: Array<any>;
    public navmethod: boolean = true;
    public canvasmethod: boolean = true;
    public visible: boolean = false;
    public open: boolean = false;
    public breakpoint: number = 991;
    public innerWidth: any;
    public currentPage: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentPage = event.urlAfterRedirects;
            }
        });
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: Event) {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            document.getElementById('can-sticky').classList.add('sticky-on');
        } else {
            document.getElementById('can-sticky').classList.remove('sticky-on');
        }
    }

    ngOnInit() {
        this.detectHeader();
        this.navigation = this.getNavigation();
    }

    public toggleNav() {
        this.navmethod = !this.navmethod;
    }

    public toggleCanvas() {
        this.canvasmethod = !this.canvasmethod;
    }

    public detectHeader() {
        this.innerWidth = window.innerWidth;
        this.visible = this.innerWidth >= this.breakpoint;
    }

    public trigger(item: { open: boolean; }) {
        item.open = !item.open;
    }

    public sectionScroll(section: string) {
        if (this.currentPage.substring(1) === Pages.Home || this.currentPage.substring(1) === 'home') {
            this.document.querySelector(section).scrollIntoView({ behavior: 'smooth' });
        } else {
            this.router.navigate([Pages.Home]);
        }
    }

    private getNavigation(): Array<any> {
        return [
            { id: 0, linkText: "Home", child: false },
            { id: 1, linkText: "About", child: false },
            { id: 2, linkText: "Services", child: false },
            { id: 3, linkText: "Portfolio", child: false },
            { id: 4, linkText: "Contact", child: false }
        ]
    }
}