<footer [class]="layout">
    <div class="container">
        <div class="footer-widget">
            <div class="row">
                <div class="col-lg-4 col-sm-5 order-1">
                    <div class="widget site-info-widget">
                        <div class="footer-logo">
                            <img [src]="logo" alt="logo" height="65">
                        </div>
                        <p>
                            Established in 2006, specialising in exterior maintenance and 
                            upkeep of commerical properties throughout the south.
                        </p>
                    </div>
                </div>
                <div class="col-lg-8 col-sm-7 order-2">
                    <div class="widget newsletter-widget">
                        <h4 class="widget-title">Subscribe Our Newsletters</h4>
                        <div class="newsletter-form">
                            <form [formGroup]="form">
                                <input type="email" formControlName='email' placeholder="Enter Your Email">
                                <button (click)="onSubmit()" type="submit" class="main-btn">Subscribe Now</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 order-3">
                    <div class="widget insta-feed-widget">
                        <div class="widget nav-widget">
                            <h4 class="widget-title">Accreditation</h4>
                            <p>
                                We are proud to be accredited so you get the best 
                                possible service from our talented tradesmen.
                            </p>
                            <div class="row">
                                <div class="col-lg-4 col-sm-4" *ngFor="let certificate of certificates$ | async">
                                    <img class="center-block" [src]=certificate.data.image alt=certificate.data.title >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 order-lg-3 order-5">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="widget nav-widget">
                                <h4 class="widget-title">Company</h4>
                                <ul>
                                    <li><a (click)="sectionScroll('#home')">Home</a></li>
                                    <li><a (click)="sectionScroll('#about')">About</a></li>
                                    <li><a (click)="sectionScroll('#services')">Services</a></li>
                                    <li><a (click)="sectionScroll('#portfolio')">Portfolio</a></li>
                                    <li><a routerLink="/contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="widget contact-widget">
                                <h4 class="widget-title">Contact Us</h4>
                                <ul class="contact-infos">
                                    <li>
                                        <a href="tel:{{ (properties$ | async)?.telephone }}">
                                            <i class="far fa-phone"></i>
                                            {{ (properties$ | async)?.telephone }}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:{{ (properties$ | async)?.email }}">
                                            <i class="far fa-envelope-open"></i>
                                            {{ (properties$ | async)?.email }}
                                        </a>
                                    </li>
                                    <li>
                                        <i class="far fa-map-marker-alt"></i>
                                        {{ (properties$ | async)?.address?.one }}, {{ (properties$ | async)?.address?.two }},
                                        {{ (properties$ | async)?.address?.city }}, {{ (properties$ | async)?.address?.postcode }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 order-lg-5 order-4">
                    <div class="widget insta-feed-widget">
                        <h4 class="widget-title">Latest Projects</h4>
                        <div class="insta-images">
                            <div class="insta-img" [style.background-image]="'url('+ item.data.images[0] +')'" *ngFor="let item of portfolio$ | async">
                                <a routerLink="/service/{{ item.data.link }}">
                                    <i class="fas fa-forward"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <p class="copyright-text">
                <span>Copyright © {{ date | date: 'yyyy' }} {{ (properties$ | async)?.name }}. All Rights Reserved</span>
                <span>
                    Made with <i class="far fa-heart" style="color:darkred"></i> by 
                    <span class="bitbatbow" (click)="bitbatbow()">bit<span>bat</span>bow</span>
                </span>
            </p>
            <a href="javascript:void(0)" class="back-to-top" (click)="gotoTop()"><i class="far fa-angle-up"></i></a>
        </div>
    </div>
    <img [src]="shape" alt="line-shape" [class]="shapeclass">
    <img [src]="shape2" alt="line-shape" [class]="shape2class">
</footer>