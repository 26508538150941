import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Firebase } from "../enums/firebase.enum";
import { Contact } from "../interface/contacts.interface";
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class ContactsFirebase {
    private newContact: Contact;

    constructor(private data: AngularFireDatabase) { }

    public add(contact: Contact) {
        this.newContact = {
            id: uuidv4(),
            date: Date.now(),
            name: contact.name,
            email: contact.email,
            telephone: contact.telephone,
            message: contact.message,
            replied: false
        }

        this.data.list(Firebase.Contacts).push(this.newContact)
    }

    public get$(): Observable<any[]> {
        return this.database().snapshotChanges().pipe(
            map(changes =>
                changes.map(c => ({ key: c.payload.key, data: c.payload.val() }))
            )
        );
    }

    public update(key: string, query: any) {
        this.database().update(key, query)
    }

    public delete(key: string) {
        this.database().remove(key);
    }

    private database() {
        return this.data.list(Firebase.Contacts)
    }
}