<div class="sidebar">
    <div class="widget cat-widget">
        <h4 class="widget-title">All Services</h4>
        <ul>
            <li *ngFor="let list of list$ | async">
                <a (click)="routing(list.data.link)">
                    {{ list.data.title }} <span><i class="far fa-angle-right"></i></span>
                </a>
            </li>
        </ul>
    </div>
</div>