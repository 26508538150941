import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BreadcrumbModule } from "angular-crumbs";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { CertificateComponent } from "./certificates/certificate.component";
import { CtaComponent } from "./cta/cta.component";
import { FooterComponent } from "./footer/footer.component";
import { FormComponent } from "./form/form.component";

// -- Components
import { LoadingComponent } from "./loading/loading.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";

const COMPONENTS = [
    LoadingComponent,
    NavigationComponent,
    BreadcrumbsComponent,
    SidemenuComponent,
    CertificateComponent,
    FormComponent,
    CtaComponent,
    SidebarComponent,
    FooterComponent
]

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        SlickCarouselModule,
        BreadcrumbModule,
        ReactiveFormsModule,
        RouterModule
    ],
    exports: COMPONENTS
})
export class CustomerSharedModule { }