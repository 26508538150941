import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Observable } from "rxjs/internal/Observable";
import { Firebase } from "../enums/firebase.enum";
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Subscriptions } from "../interface/subscriptions.interface";

@Injectable({
    providedIn: 'root'
})
export class SubscriptionsFirebase {
    private newSub: Subscriptions;

    constructor(private data: AngularFireDatabase) { }

    public get$(): Observable<any[]> {
        return this.database().snapshotChanges().pipe(
            map(changes =>
                changes.map(c => ({ key: c.payload.key, data: c.payload.val() }))
            )
        );
    }

    public add(subscription: Subscriptions) {
        this.newSub = {
            id: uuidv4(),
            date: Date.now(),
            email: subscription.email
        }

        this.data.list(Firebase.Subscriptions).push(this.newSub);
    }

    public update(key: string, query: any) {
        this.database().update(key, query)
    }

    public delete(key: string) {
        this.database().remove(key);
    }

    private database() {
        return this.data.list(Firebase.Subscriptions)
    }

}