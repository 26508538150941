import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { Firebase } from "../enums/firebase.enum";
import { Certificates } from "../interface/certificate.interface";
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class CertificatesFirebase {
    private newCertificates: Certificates;

    constructor(private data: AngularFireDatabase) { }

    public add(certificates: Certificates) {
        this.newCertificates = {
            id: uuidv4(),
            date: Date.now(),
            title: certificates.title,
            url: certificates.url
        }

        this.data.list(Firebase.Certificates).push(this.newCertificates);
    }

    public get$(): Observable<any[]> {
        return this.database().snapshotChanges().pipe(
            map(changes =>
                changes.map(c => ({ key: c.payload.key, data: c.payload.val() }))
            )
        );
    }

    public update(key: string, query: any) {
        this.database().update(key, query)
    }

    public delete(key: string) {
        this.database().remove(key);
    }

    private database() {
        return this.data.list(Firebase.Certificates)
    }
    
}