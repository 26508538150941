import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Pages } from "src/app/enums/pages.enum";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    @Input() public list$: Observable<any[]>;

    @Output() link: EventEmitter<string> = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {

    }

    public routing(link: string) {
        this.link.emit(link);
    }
}