<div class="widget about-widget">
    <div class="logo mb-20">
        <img src="assets/logo/ejfm-black.png" alt="img" height="65">
    </div>
    <p>
        Established in 2006, {{ (properties$ | async)?.name }} specialises in exterior maintenance and 
        upkeep of commerical properties throughout the south.
    </p>
</div>
<div class="widget contact-info-widget">
    <h5 class="widget-title">Contact Us</h5>
    <ul>
        <li>
            <i class="far fa-phone"></i>
            {{ (properties$ | async)?.telephone }}
        </li>
        <li>
            <i class="far fa-envelope-open"></i>
            {{ (properties$ | async)?.email }}
        </li>
        <li>
            <i class="far fa-map-marked-alt"></i>
            {{ (properties$ | async)?.address?.one }}, {{ (properties$ | async)?.address?.two }}, 
            {{ (properties$ | async)?.address?.city }}, {{ (properties$ | async)?.address?.postcode }}
        </li>
    </ul>
</div>
<div class="widget social-link">
    <h5 class="widget-title">Login</h5>
    <p>
        Please use the provided credentials to access you personalised dashboard.
    </p>
    <button routerLink="/login" class="sidemenu-button">
        Admin Login
    </button>
</div>