<form [formGroup]="form">
    <div class="row">
        <div class="col-lg-4">
            <div class="input-group mb-30">
                <input type="text" placeholder="Your Full Name" formControlName='name' required>
                <span class="icon"><i class="far fa-user-circle"></i></span>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="input-group mb-30">
                <input type="email" placeholder="Your Email Address" formControlName='email' required>
                <span class="icon"><i class="far fa-envelope-open"></i></span>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="input-group mb-30">
                <input type="text" placeholder="Your Phone" formControlName='telephone' required>
                <span class="icon"><i class="far fa-phone"></i></span>
            </div>
        </div>
        <div class="col-12">
            <div class="input-group textarea mb-30">
                <textarea placeholder="Write Message" formControlName='message'></textarea>
                <span class="icon"><i class="far fa-pencil"></i></span>
            </div>
        </div>
        <div class="col-12 text-center">
            <button type="submit" (click)="onSubmit()" class="main-btn">Send Message</button>
        </div>
    </div>
</form>