import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CertificatesFirebase } from 'src/app/firebase/certificates.firebase';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
    public certificates$: Observable<any>;
    public settings = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        arrows: false,
        fade: false,
        dots: false,
        swipe: true,
        responsive: [{
          breakpoint: 991,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
          },
        },
        ]
      };
  
  constructor (private certificatesFirebase: CertificatesFirebase) {
      this.certificates$ = this.certificatesFirebase.get$();
  }

  ngOnInit() {

  }
}