<div class="row align-items-center justify-content-between">
    <div class="col" *ngFor="let certificate of certificates$ | async">
        <div class="client-img d-block text-center">
            <img [src]="certificate.data.image" [alt]="certificate.data.title" width="30%"> 
        </div>
    </div>
</div>
<!-- <ngx-slick-carousel #slickModal="slick-carousel" [config]="settings" class="row align-items-center justify-content-between" id="clientSlider">
    <div ngxSlickItem class="col" *ngFor="let certificate of certificates$ | async">
        <div class="client-img d-block text-center">
            <img [src]="certificate.data.image" [alt]="certificate.data.title" width="30%"> 
        </div>
    </div>
</ngx-slick-carousel> -->