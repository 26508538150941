import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BreadcrumbModule } from "angular-crumbs";
import { SlickCarouselModule } from "ngx-slick-carousel";

const COMPONENTS = [
     
]

@NgModule({
    declarations: COMPONENTS,
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        SlickCarouselModule,
        BreadcrumbModule,
        ReactiveFormsModule,
        RouterModule
    ],
    exports: COMPONENTS
})
export class AdminSharedModule { }