import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Observable } from "rxjs";
import { Firebase } from "../enums/firebase.enum";

@Injectable({
    providedIn: 'root'
})
export class PropertiesFirebase {

    constructor(private data: AngularFireDatabase) { }

    public get$(): Observable<any> {
        return this.database().valueChanges()
    }

    private database() {
        return this.data.object(Firebase.Properties)
    }
}
