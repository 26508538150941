import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Pages } from './enums/pages.enum';

const routes: Routes = [
  {
    path: '', redirectTo: Pages.Home, pathMatch: 'full'
  },
  {
    path: Pages.Login, loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: Pages.Home, loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: Pages.Contact, loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: Pages.Portfolio, loadChildren: () => import('./components/pages/portfolio/portfolio.module').then(m => m.PortfolioModule)
  },
  {
    path: Pages.Services, loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule)
  },
  {
    path: Pages.Login, loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: Pages.Admin, loadChildren: () => import('./components/admin/dashboard/dashboard.module').then(m => m.DashboardModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
