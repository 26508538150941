<header class="header-two sticky-header" id="can-sticky">
    <!-- Header Topbar -->
    <div class="header-topbar">
        <div class="container-fluid container-1600">
            <div class="header-topbar-inner d-md-flex align-items-center justify-content-between">
                <ul class="contact-info">
                    <li>
                        <a href="tel:{{ (properties$ | async)?.telephone }}"><i class="far fa-phone"></i>{{ (properties$ | async)?.telephone }}</a>
                    </li>
                    <li>
                        <a href="mailto:{{ (properties$ | async)?.email }}"><i class="far fa-envelope-open"></i>{{ (properties$ | async)?.email }}</a>
                    </li>
                    <li>
                        <i class="far fa-map-marker-alt"></i>{{ (properties$ | async)?.address?.one }}, {{ (properties$ | async)?.address?.two }}, {{ (properties$ | async)?.address?.city }}, {{ (properties$ | async)?.address?.postcode }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="header-nav sticky-nav">
        <div class="container-fluid container-1600">
            <div class="nav-container" [ngClass]="visible ? '' : 'breakpoint-on'" (window:resize)="detectHeader()">
                <div class="site-logo">
                    <a routerLink="/"><img src="assets/logo/ejfm-white.png" alt="logo" height="65"></a>
                </div>

                <div class="nav-menu d-lg-flex align-items-center" [ngClass]="navmethod ? '' : 'menu-on'">

                    <div class="navbar-close">
                        <div class="cross-wrap" (click)="toggleNav()"><span></span><span></span></div>
                    </div>

                    <div class="menu-items">
                        <ul>
                            <li class="menu-item">
                                <a (click)="sectionScroll('#home')">Home</a>
                            </li>
                            <li class="menu-item">
                                <a (click)="sectionScroll('#about')">About</a>
                            </li>
                            <li class="menu-item">
                                <a (click)="sectionScroll('#services')">Services</a>
                            </li>
                            <li class="menu-item">
                                <a (click)="sectionScroll('#portfolio')">Portfolio</a>
                            </li>
                            <li class="menu-item">
                                <a routerLink="/contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div class="nav-pushed-item"></div>
                </div>
                <div class="navbar-extra d-flex align-items-center">
                    <div class="offcanvas-toggler" (click)="toggleCanvas()">
                        <span><span></span><span></span><span></span></span>
                    </div>
                    <div class="navbar-toggler" [ngClass]="navmethod ? '' : 'active'" (click)="toggleNav()">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="offcanvas-wrapper" [ngClass]="canvasmethod ? '' : 'show-offcanvas'">
    <div class="offcanvas-overly" [ngClass]="canvasmethod ? '' : 'show-overly'" (click)="toggleCanvas()"></div>
    <div class="offcanvas-widget">
        <a href="javascript:void(0)" class="offcanvas-close" (click)="toggleCanvas()"><i class="fal fa-times"></i></a>
        <app-sidemenu [properties$]="properties$"></app-sidemenu>
    </div>
</div>